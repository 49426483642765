@import "./themes/themes.scss";
@import "./transaction/trasnaction.scss";
@import "./accounts/accounts.scss";
@import "./navbar/navbar.scss";
@import "./login/login.scss";
@import "./shared/components.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=color] {
  height: 38px;
}

a {
  outline: none;
}

.app-theme-switch {
  padding-top: 0.3rem;
}

html, body {
  color: var(--main-content-text-color);
  background: var(--main-content-background-color);
  font-size: 16px;
}

.form-select {
  color: var(--main-content-text-color);
  background-color: var(--header-background-color);
  &:disabled {
    background: var(--main-content-background-color);
  }
}

.list-group {
  color: var(--main-content-text-color);
  background: var(--header-background-color);
}

.list-group-item {
  color: var(--main-content-text-color);
  background: var(--header-background-color);
}

.form-control {
  color: var(--main-content-text-color);
  background-color: var(--header-background-color);
  &:focus {
    color: var(--main-content-text-color);
  }
  &:disabled {
    background-color: var(--main-content-background-color);
  }
}

.dropdown-menu {
  background-color: var(--header-background-color) !important;
  color: var(--main-content-text-color);
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #0C9;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}


.my-float {
  margin-top: 22px;
}

.btn-circle {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 35px;
  text-align: center;
  font-size: 30px;
  line-height: 1;
}

.transaction-actions .btn {
  padding: 0
}

.test {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.color-pick {
  cursor: pointer;
}

.list-item {
  background: var(--header-background-color);
  border-radius: 10px;
  padding: 0.5rem;
  &.click {
    cursor: pointer;
  }
}

