.navbar {
  background-color: var(--main-content-background-color) !important;
  padding-left: 0;
  padding-bottom: 0;

  .nav-link {
    &.active {
      font-weight: bold;
    }
  }

  .navbar-toggler {
    &:focus {
      box-shadow: 0 0 0 0;
    }
  }

}
