[data-bs-theme="light"] {
  --header-background-color: #eaeaea;
  --header-border-color: #e5e8ea;
  --header-menu-item-color: #231f20;
  --header-theme-switch-background-color: #cccccc;
  --header-theme-switch-before-background-color: #ffffff;
  --header-theme-switch-checked-background-color: #2196f3;

  --main-content-background-color: #ffffff;
  --main-content-text-color: #231f20;

  --hamburger-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

  --bs-gutter-y: 8;

}
