.transactions-list-day {
  font-size: 1.5rem;
  padding-top: 0.5rem;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
}

.transactions-list-item {
  background: var(--header-background-color);
  border-radius: 10px;
  padding: 0.5rem;
}
