[data-bs-theme="dark"] {
  --header-background-color: #2f3034;
  --light: #2f3034;
  --header-border-color: #e5e8ea;
  --header-menu-item-color: #ffffff;
  --header-theme-switch-background-color: #cccccc;
  --header-theme-switch-before-background-color: #ffffff;
  --header-theme-switch-checked-background-color: #2196f3;

  --main-content-background-color: #231f20;
  --main-content-text-color: #ffffff;

  --hamburger-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
